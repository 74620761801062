<template>
  <div>
    <div class="flex-fixed" v-if="false">
      <div class="bird-contaion">
        <div class="birder-invite" @click="toMyInvite">我的邀请</div>
        <div class="bird-total">
          <img
            src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/action.png"
            class="bird-action"
          />
          <img
            src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/title.png"
            class="bird-title"
          />
          <img
            src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/infor.png"
            class="bird-infor"
          />
          <img
            src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/time.png"
            class="bird-time"
          />
        </div>
        <div class="bird-con">
          <div class="title-flex">
            <div
              class="title-name"
              :class="selected == index ? 'title-select' : 'title-no'"
              v-for="(item, index) in titleList"
              :key="index"
              @click="titleClick(index)"
            >
              <span :class="selected == index ? 'selected' : 'no-selected'">{{
                item
              }}</span>
            </div>
          </div>
          <div class="title-con">
            <span class="take">参与方式</span>
            <div class="take-con">
              <div class="take-con-one">
                <img
                  src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/way-1.png"
                  alt="1"
                  class="teke-con-left"
                />
                <span class="take-con-right"
                  >转发活动海报到朋友圈保存7天，并集齐10个以上点赞量。</span
                >
              </div>
              <div class="take-con-one">
                <img
                  src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/way-2.png"
                  alt="1"
                  class="teke-con-left"
                />
                <span class="take-con-right"
                  >通过易享家小程序或APP，下单预定任意一套{{
                    selected == 0
                      ? "学生公寓或学生公寓预定服务"
                      : "民宿房源或VIP定制租房服务"
                  }}。</span
                >
              </div>
            </div>
            <span class="take">优惠好礼</span>
            <div class="take-con">
              <div
                class="reduced-flex"
                v-for="(item, it) in reduceList"
                :key="it"
              >
                <img
                  :src="item.gift"
                  :style="it == 0 ? 'margin-right:10px' : ''"
                  alt=""
                  class="gift"
                />
                <img :src="item.give" v-if="it != 0" alt="" class="give" />
                <div class="reduced-text">
                  {{ item.content }}
                  <span style="color: #ffa164">{{ item.price }}</span
                  ><span>{{ item.con }}</span>
                </div>
              </div>
              <div class="active-infor">*该活动最终解释权归我方平台所有。</div>
            </div>
          </div>
        </div>
        <div :style="selected == 0 ? 'height:163.5px' : 'height:158.5px'"></div>
      </div>
    </div>
    <div class="bird-bg">
      <div class="bird-invite" @click="toMyInvite"></div>
    </div>

    <div class="bird-bottom">
      <div class="flex-bottom">
        <div class="bird-bot-flex">
          <div class="bot-flex" @click="wxZf">
            <img
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/wx.png"
              alt="微信"
            />
            <div class="bot-text">转发好友</div>
          </div>
          <div class="bot-flex" @click="friendZf">
            <img
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/circle.png"
              alt="朋友圈"
            />
            <div class="bot-text">朋友圈</div>
          </div>
          <div class="bot-flex" @click="genPoster">
            <img
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/posters.png"
              alt="生成海报"
            />
            <div class="bot-text">生成海报</div>
          </div>
          <div class="bot-flex" @click="kf">
            <img
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/service.png"
              alt="客服"
            />
            <div class="bot-text">客服</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $birdApi from "@/api/bird.js";
export default {
  name: "EarlyBird",
  data() {
    return {
      titleList: ["学生公寓", "优质民宿"],
      selected: 0,
      reduce: [],
      reduceList: [
        {
          gift: "https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/gift.png",
          give: "https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/give.png",
          content:
            "通过个人专属链接成功邀请一位好友下单订房并成功入住，即可赢得",
          price: "300元现金奖励",
          con: ",上不封顶。",
        },
        {
          gift: "https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/gift.png",
          give: "https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/give.png",
          content: "海外电话卡一张",
          price: "",
          con: "",
        },
        {
          gift: "https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/gift.png",
          give: "https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/give.png",
          content: "出国安心包一套（快递寄送）",
          price: "",
          con: "",
        },
        {
          gift: "https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/gift.png",
          give: "https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/give.png",
          content: "民宿房源租金优惠券，预定指定房源每周房租最高可降",
          price: "$70",
          con: "",
        },
        {
          gift: "https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/gift.png",
          give: "https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/give.png",
          content: "VIP定制租房服务",
          price: "8-9折",
          con: "预定优惠券",
        },
      ],
      //邀请码
      inviteCode: "inviteCode",
      //海报地址
      postUrl: "",
      head: {
        token: "",
        agent: "",
      },
      test: "2312312",
    };
  },
  created() {
    this.selected = this.$route.query.selected;
    this.reduce = this.reduceList;
    this.titleClick(this.selected || 0); //0学生公寓; 1民宿

    hiddenShareButton.postMessage("");
  },
  mounted() {
    //获取邀请码，APP主动调用
    window["getTokenX"] = (header) => {
      if (header) {
        this.head = JSON.parse(header);
      }
    };

    window["getInviteCodeX"] = (code) => {
      this.inviteCode = code;
    };

    window["webviewBackOnShowX"] = () => {
      this.initEvent();
    };
    this.initEvent();
  },
  destroyed() {
    window["getTokenX"] = undefined;
    window["getInviteCodeX"] = undefined;
    window["webviewBackOnShowX"] = undefined;
  },
  methods: {
    initEvent() {
      //调用APP获取 inviteCode
      getToken.postMessage("");
      getInviteCode.postMessage("");
    },
    getPostUrl() {
      return new Promise((resolve, reject) => {
        if (this.postUrl) {
          resolve(this.postUrl);
        } else {
          this.$loading();
          $birdApi
            .poster(
              {
                param: this.inviteCode,
                scene: 6,
              },
              this.head
            )
            .then((res) => {
              if (res.code == 0) {
                this.postUrl = res.data;
                resolve(this.postUrl);
              } else {
                this.$failToast(res.message);
                reject();
              }
            })
            .finally(() => {
              this.$clearLoading();
            });
        }
      });
    },
    titleClick(index) {
      this.selected = index;
      if (index == 0) {
        this.reduceList = this.reduceList.slice(0, 3);
      } else if (index == 1) {
        this.reduceList = this.reduce;
      }
    },
    toMyInvite() {
      //判断是否登录
      if (this.inviteCode) {
        this.$router.push({
          path: "/myInvite",
        });
      } else {
        this.toLogin();
      }
    },
    toLogin() {
      this.$toast("请先登录");
      enterLoginPage.postMessage("");
    },
    //微信转发
    wxZf() {
      //判断是否登录
      if (this.inviteCode) {
        shareMiniProgramToFriends.postMessage(
          JSON.stringify({
            thumnail:
              "https://img1.clozhome.com/new-clozhome/mini/myPages/static/bird/bird_s2_register.png",
            title: `2022年S2早鸟房源预定活动`,
            miniPath: `/myPages/birdS2/register?inviteCode=${this.inviteCode}&selected=${this.selected}`,
          })
        );
      } else {
        this.toLogin();
      }
    },
    //朋友圈转发
    friendZf() {
      //判断是否登录
      if (this.inviteCode) {
        this.getPostUrl().then((postUrl) => {
          sharePosterToCircle.postMessage(
            JSON.stringify({
              thumnail: postUrl,
              posterUrl: postUrl,
              title: `2022年S2早鸟房源预定活动`,
            })
          );
        });
      } else {
        this.toLogin();
      }
    },
    //海报
    genPoster() {
      if (this.inviteCode) {
        this.getPostUrl().then((postUrl) => {
          enterGeneratePosterPage.postMessage(
            JSON.stringify({
              posterUrl: encodeURIComponent(postUrl),
            })
          );
        });
      } else {
        this.toLogin();
      }
    },
    //客服
    kf() {
      enterMeiQiaService.postMessage("");
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-fixed {
  position: fixed;
  height: 87%;
  overflow: hidden;
  overflow-y: auto;
}

.bird-contaion {
  padding: 20px 20px 0 20px;
  background: url("https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/backdrop.png")
    no-repeat;
  background-size: 100% 100%;
}
.bird-bg {
  width: 100%;
  height: 1164px;
  //   padding: 20px 20px 0 20px;
  background: url("https://img1.clozhome.com/new-clozhome/mini/myPages/static/bird/bird_s2_bg.png")
    no-repeat;
  background-size: 375px 1064px;
  .bird-invite {
    position: absolute;
    width: 100px;
    height: 100px;
    // background-color: rgba(39, 133, 139, .3);
  }
}

.birder-invite {
  font-size: 12px;
  color: #333333;
  padding: 10px;
  display: inline-block;
}

.bird-total {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.bird-action {
  width: 180.5px;
  height: 40px;
  object-fit: contain;
  margin-top: 20px;
}

.bird-title {
  width: 322.5px;
  height: 28.5px;
  object-fit: scale-down;
  margin-top: 15px;
}

.bird-infor {
  width: 230.5px;
  height: 15px;
  object-fit: contain;
  margin-top: 20px;
}

.bird-time {
  width: 220px;
  height: 32.5px;
  object-fit: scale-down;
  margin-top: 20px;
}

.bird-con {
  margin-top: 40px;
  background: url("https://img1.clozhome.com/new-clozhome/mini/app_page_assets/bird/line.png")
    no-repeat;
  background-size: 100% 100%;
  padding: 13px;
}

.title-flex {
  display: flex;
}

.title-name {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.title-name span {
  padding: 4px 9px;
  font-size: 15px;
  color: #fff;
  border-radius: 4px;
}

.title-select {
  background: #fff;
}

.title-no {
  background: #f2f2f2;
}

.selected {
  background: #ffa164;
}

.no-selected {
  background: #999999;
}

.title-con {
  background: #fff;
  padding: 20px 0;
  border-radius: 5px;
  border-top-left-radius: 0;
}

.title-con .take {
  padding: 4px 18px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
  background: rgba(39, 133, 139, 1);
  border-top-right-radius: 19px;
  border-bottom-right-radius: 19px;
}

.take-con {
  padding: 0 18px;
}

.take-con-one {
  display: flex;
  margin-top: 15px;
  margin-bottom: 20px;
}

.take-con-one .teke-con-left {
  width: 37px;
  height: 17px;
}

.take-con-one .take-con-right {
  padding-top: 6px;
  margin-left: 12px;
  font-size: 12px;
  color: #333333;
  letter-spacing: 1px;
}

.reduced-flex {
  display: flex;
  margin-top: 16px;
}

.reduced-flex .gift {
  width: 25px;
  height: 25px;
}

.reduced-flex .give {
  width: 22px;
  height: 21.5px;
  margin: 0 10px;
}

.reduced-text {
  font-size: 12px;
  color: #333;
  line-height: 17px;
}

.active-infor {
  font-size: 9px;
  color: #666666;
  margin-top: 21px;
  letter-spacing: 1px;
}

//底部
.bird-bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 111;
  padding: 13px 0 16px 0;
  background: #fff;
}

.bird-bot-flex {
  display: flex;
  justify-content: center;
}

.bot-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;
}

.bird-bot-flex .bot-flex img {
  width: 40px;
  height: 40px;
}

.bot-flex .bot-text {
  font-size: 12px;
  margin-top: 10px;
}
</style>
